const baseconfig = {
    host: 'https://guide-test.16u.cc/',
    imghost:'https://guide-test.16u.cc/resource/images/',
    
    // host: 'https://www.aidaolan.com/',
    // imghost:'https://www.aidaolan.com/resource/images/',

    ///production生产环境，development开发环境'/api',用于ajax请求连接自动补齐前面http网址，即该链接为 http网址+/api
    api_baseURL: '/website',
    isLoscal_debug: process.env.NODE_ENV=='production'?'0':'1',
}


module.exports = baseconfig