<template>
    <div class="container-home survey-home">
        <HeaderTab ></HeaderTab>
        <top-banner></top-banner>
        <main class="main-auto">
            <my-breadcrumb></my-breadcrumb>
            <section class="flex main">
                <aside>
                    <div @click.stop="getModel(item,item.page,item.name)"  v-for="(item,index) of tabList" :key="index" :class="['nav-item',item.id == id ? 'active' : ''] ">{{item.name}}</div>
              
                </aside>
                <section>
                    <div class="article-title fwb flex ac jb">
                         <div>{{secondMenu}}</div> 
                          <div v-if="showDetail" @click="back" class="flex ac jc">
                                <div class="btn more-btn m-t-0">{{$t('button.return')}}</div>
                            </div>
                    </div>
                    <article>
                        <activity :style="{ display : !showDetail ? '' : 'none'  }"  @querydetail="querydetail"></activity>
                        <div :style="{ display : showDetail ? '' : 'none'  }" >
                            <detail-info  :htmlData="htmlData" :title="title"></detail-info>
                           
                        </div>
                      
                    </article>
                </section>
            </section>
        </main>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex"
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import ArticleLayout from '@/components/ArticleLayout.vue';
import footerTab from "@/components/Footer-bottom.vue";
import detailInfo from '@/components/DetailInfo';
import activity from '@/components/Activity';
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        MyBreadcrumb,
        ArticleLayout,
        footerTab,
        TopBanner,
        activity,
        detailInfo,
    },
    
    data() {
        return {
            showDetail : false,
            htmlData : "",
            title : ""
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    computed: {
      ...mapGetters(['tabList','secondMenu']),
    },
   async  mounted() {
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
     
    },
    methods: {
        back(){
            this.htmlData = "";
            this.showDetail = false
        },
        querydetail(value,title){
            this.showDetail = true;
            this.htmlData = value;
            this.title = title
        },
        getModel(e,url,secondMenu) {
            const ts = this
            let firstMenu = "";
            this.$store.getters.menuList.forEach( (item) => {
                if ( item.id == e.parent_id ) {
                    firstMenu = item.name
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url,data : { id : e.id,parent_id :e.parent_id }})
         
        },
 
    }
}
</script>
<style scoped>
    .btn {
        width: 100px;
        margin-top: 60px;
    }
</style>