<template>
   <div>
         <div class="container flex fw ">
         <div :class="['list-item',(index + 1)%3 == 0 ? 'm-r-0' : '' ]" v-for="(item,index) of list" :key="index">
            <div :style="{ backgroundImage : 'url(' + item.domain_image +')' }" class="images">
                  <div class="mian-box flex ac jc">
                       <div v-if="config && config.goods_info" class="code" :style="{ backgroundImage : 'url(' + config.goods_info.image.value+')' }">

                       </div>
                  </div>
            </div>
            <div class="list-item-title double">
                {{ item.name }}
            </div>
          
        </div>
         
    </div>
      <pagination-box :pagination="pagination" @pageChange="pageChange" ></pagination-box>
   </div>
  
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex"
import paginationBox from '@/components/common/pagination.vue'; 
import { Indicator } from "mint-ui";
export default {
    data(){
        return {
            list : [
            ],
            pagination: {}, //分页数据
            page:1
        }
    },
    components: {
		paginationBox,
	},
    computed : {
         ...mapGetters(['config']),
    },
  async mounted() {
        Indicator.open(this.$t('tip.loading'));
        await this.commonGetList('os_goods');
        Indicator.close()
    },
    methods : {
          commonGetList(table){
            const ts = this;
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : 6 , page : ts.page}
                }).then( (res) => {
                    if ( res.code ) {
                        ts.list = res.data.list
                         ts.pagination = {
                            page: res.data.current_page,
                            pagecount: res.data.last_page,
                            total: res.data.total,
                            pagesize: res.data.per_page
                        }
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
                   // 分页操作
         pageChange: async function(page) {
            this.page = page
              Indicator.open(this.$t('tip.loading'));
                await this.commonGetList('os_goods');
                Indicator.close()
            },
    }
}
</script>
<style lang="scss" scoped>
    .container {
        margin-top: 31px;
        .list-item {
            width: calc(33.333% - 16px);
            height: 340px;
            margin-right: 24px;
            margin-bottom: 24px;
            position: relative;
           
            .images {
                width: 100%;
                height: 257px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 4px;
                position: relative;
                .mian-box {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    transition: background 0.3s linear;
                    // background: rgba(0, 0, 0, 0.8);
                    .code {
                        width: 100px;
                        height: 100px;
                        background-size: 100% 100%;
                        opacity: 0;
                        transition: background 0.3s linear;
                    }
                }
                &:hover > .mian-box {
                    background: rgba(0, 0, 0, 0.8);
                }
                  &:hover > .mian-box > .code {
                    opacity: 1 !important;
                }
            }
             
            .list-item-title {
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: bold;
                color: #000000;
                margin-top: 16px;
            }
            .list-item-open-time {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-top: 8px;
            }
            .list-item-desc {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-top: 8px;
            }
            .list-item-label {
                padding: 4px 8px;
                font-size: 10px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: var(--bg-color);
                border: 1px solid var(--bg-color);
                border-radius: 12px;
            }
            .query-detail {
             
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: var(--bg-color);
            }
        }
    }
    .m-t-13 {
        margin-top: 13px;
    }
    .m-r-0 {
        margin-right: 0 !important;
    }
</style>