<template>
    <div class="container-home survey-home">
        <HeaderTab ></HeaderTab>
        <top-banner></top-banner>
        <main class="main-auto">
            <my-breadcrumb></my-breadcrumb>
            <section class="flex main">
                <aside>
                    <div @click.stop="getModel(item,item.page,item.name)"  v-for="(item,index) of tabList" :key="index" :class="['nav-item',item.id == id ? 'active' : ''] ">{{item.name}}</div>
              
                </aside>
                <section>
                    <div class="article-title fwb">{{secondMenu}}</div>
                    <article>
                        <traffic />
                    </article>
                </section>
            </section>
        </main>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import { Indicator } from "mint-ui";
import { mapGetters } from "vuex"
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import ArticleLayout from '@/components/ArticleLayout.vue';
import footerTab from "@/components/Footer-bottom.vue";
import traffic from '@/components/Traffic';
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        MyBreadcrumb,
        ArticleLayout,
        footerTab,
        TopBanner,
        traffic
    },
    
    data() {
        return {
            info : {
                guide_map_info : {
                    image : {
                        value : []
                    }
                }
            } 
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    computed: {
      ...mapGetters(['tabList','secondMenu']),
    },
   async  mounted() {
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
    },
    methods: {
        getModel(e,url,secondMenu) {
            const ts = this
            let firstMenu = "";
            this.$store.getters.menuList.forEach( (item) => {
                if ( item.id == e.parent_id ) {
                    firstMenu = item.name
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url,data : { id : e.id,parent_id :e.parent_id }})
         
        },

 
    }
}
</script>
<style scoped>
   
</style>