<template>
    <div v-if="config" class="container">
        <div v-if="config.visitors_notice_info.buy_tickets_content.value" class="list-item-box flex ac">
            <!-- <div  class="buy-ticket-icons icons ">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title">  {{$t('tip.ticketPurchaseInstructions')}} </div>
                 <div class="list-otem-detail" v-html="config.visitors_notice_info.buy_tickets_content.value"> </div>
            </div>
            <div class="line-icon"></div>
        </div>
         <div v-if="config.visitors_notice_info.enter_park.value" class="list-item-box flex ac">
            <!-- <div  class="entry-park-icons  icons">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title">{{$t('tip.admissionNotice')}} </div>
                 <div class="list-otem-detail" v-html="config.visitors_notice_info.enter_park.value"></div>
            </div>
            <div class="line-icon"></div>
        </div>

         <div v-if="config.visitors_notice_info.security_content.value" class="list-item-box flex ac">
            <!-- <div  class="security-icons icons">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title"> {{$t('tip.safetyInstructions')}} </div>
                 <div class="list-otem-detail" v-html="config.visitors_notice_info.security_content.value"></div>
            </div>
            <div class="line-icon"></div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
    data(){
        return {
            
        }
    },
       async  mounted() {
       
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
    },
    computed: {
      ...mapGetters(['config']),
    },
        methods: {
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep{
      .list-item{
        ul,ol {
          padding-left: 20px;
          list-style: revert;
        }
      }
    }
    .container >>> img {
            max-width: 1000px !important;
        }
    .container {
        margin-top: 52px;
        .list-item-box {
            border: 1px solid #E3E3E3;
            padding: 15px;
            margin-bottom: 24px;
            position: relative;
            .icons {
                width: 60px;
                height: 60px;
                background-size: 100% 100%;
                margin-right: 30px;
            
            }
            .buy-ticket-icons {
                background-image: url("../assets/buy-ticket-icons.png");
            }
            .entry-park-icons {
                background-image: url("../assets/entry-park-icons.png");
            }
            .security-icons {
                background-image: url("../assets/security.png");
            }
            .list-item {
                flex: 1;
                .list-item-title {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 8px;
                }
                .list-otem-detail {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
            .line-icon {
                position: absolute;
                left: 0;
                width: 4px;
                height: 48px;
                background-color: var(--bg-color);
            }
        }
    }
</style>