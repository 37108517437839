<!-- 顶部banner -->
<template>
    <div class="detail-info" v-if="fileData.length>0">
        <div class="mapeditor-title">附件下载</div>
        <div v-for="(item,index) in fileData"  :key="index" style="margin-bottom: 10px">
            <a :href="item.url" style="text-decoration:none;color:#000000;">附件{{(parseInt(index)+1)}}: {{item.name}}</a>
        </div>
    </div>
</template>
<script>
var util = require("@/utils/util");
export default {
    name: "detail-info",
    props: {
        fileData : Array,
    },
    computed: {
    },
    data() {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
    .detail-info >>> img {
        max-width: 1000px !important;
    }
    .title {
        font-size: 30px;
        font-weight: bold;
        
    }
     .mapeditor-title {
        padding-left: 10px;
        background-color: rgba($color: #f0f2f5, $alpha: 0.5);
        border-left: 4px solid;
        border-color: #409eff;
        margin-bottom: 10px;
        height: 30px;
        line-height: 30px;
        span {
            font-size: 12px;
            margin-left: 16px;
            color: #f56c6c;
        }
    }
</style>