<template>
    <div  class="flex dc ac jc m-t-66">
        <div v-if="config && config.message_board_info && config.message_board_info.content" class="title" v-html="config.message_board_info.content.value"></div>
         <el-form ref="form" class="form" :model="form" label-width="0px">
            <el-form-item label="">
              <el-select v-model="form.complaint_id" :placeholder="$t('tip.type')">
                <el-option :label="$t('tip.consumption')" value="1"></el-option>
                <el-option :label="$t('tip.service')" value="2"></el-option>
                <el-option :label="$t('tip.security')" value="3"></el-option>
                <el-option :label="$t('tip.traffic')" value="4"></el-option>
                <el-option :label="$t('tip.environment')"  value="5"></el-option>
                <el-option :label="$t('tip.facilities')" value="6"></el-option>
                <el-option :label="$t('tip.stay')" value="7"></el-option>
                <el-option :label="$t('tip.other')" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-input type="textarea" rows="4" :placeholder="$t('tip.content')" v-model="form.content"></el-input>
            </el-form-item>
              <el-form-item label="">
                <el-input :placeholder="$t('tip.contact')" v-model="form.tel"></el-input>
            </el-form-item>
            <div class="flex ac">
                <el-form-item  label="" >
                        <div class="flex ac">
                             <el-input class="captcha" v-model="form.captcha" name="captcha" type="text" :placeholder="$t('tip.code')" >
                              
                            </el-input>
                              <img class="code-image" slot="append" :src="captchaImg" />
                            <div @click="getCaptcha()" class="flex ac code-review">
                                <div class="review"></div>
                                <div class="review-text">{{$t('aboutWe.changeOne')}}</div>
                            </div>
                        </div>
                      
                </el-form-item>
              
            </div>
              
            <el-form-item>
                <div class="more m-t-10">
                    <span @click="onSubmit" class="btn more-btn">{{$t('aboutWe.submit')}}</span>
                </div>
            </el-form-item>
            </el-form>
    </div>
   

</template>

<script>
import { mapGetters } from "vuex"
var util = require("@/utils/util");
import { Indicator,Toast  } from "mint-ui";
  export default {
    data() {
      return {
        form: {
          complaint_id: '', // 类型
          content: '',// 内容
          tel: '',// 联系方式
          captcha : '',// 验证码
          captchaImg : "",
          
        },
      }
    },
    computed: {
      ...mapGetters(['config']),
    },
    created: function() {
        this.getCaptcha();
    },
    methods: {
      onSubmit() {
       Indicator.open(this.$t('tip.Submitting'));
        const ts = this;
        util.requests('post',{
          url : "os/bbsSubmit",
          data : this.form,
        }).then( (res) => {
          if ( res.code ) {
            ts.form =  {
              complaint_id: '', // 类型
              content: '',// 内容
              tel: '',// 联系方式
              captcha : '',// 验证码
              captchaImg : "",
            }
          } else {
            ts.getCaptcha()
          }
          Indicator.close()
         Toast(res.msg);
          ts.loading = false
        })
      },
      getCaptcha() {
          let random_num = Math.random();
          this.captchaImg =  "/api/website/Captcha/index?r=" + random_num;
          // this.captchaImg = "http://localhost:8085/api/website/Captcha/index?r=" + random_num;
         this.$forceUpdate()
    },
    }
  }
</script>
<style scoped>
    .m-t-66 {
        margin-top: 66px;
    }
    .submit-button {
        width: 100%;
    }
    .el-form-item,.el-select {
        width: 479px;
    }
    .review {
        width: 18px;
        height: 18px;
        background-size: 100% 100%;
        background-image: url("../assets/review.png");
    }
    .review-text {
        font-size: 16px;
        color: var(--bg-color);
    }
    .captcha {
        flex: 1 !important;
    }
    .code-review {
        width: 80px;
    }
    .code-image {
        width: 120px;
        margin: 0 15px;
    }
    .title {
      max-width: 700px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 600;
        color: #000000;
        margin-bottom: 34px;
    }
</style>